<template>
    <div class="menu">
        
        <!-- MENUS -->
        <!-- <ul id="nav-dne" v-if="showContent && ( dataSource == 'menus' || dataSource == 'search' )">
            <li v-for="(menu, index) in menus" :key="index">
                <a :href="`/${flagSelected}/${menu.slug}`">
                    <span>{{menu.name}}</span>
                </a>
            </li>
        </ul> -->

        <!-- MENUS ENTITIES -->
        <div class="menu-entities" v-if="showContent && ( dataSource == 'menus' || dataSource == 'search' )">
            <div class="entity">
                <div class="openMenu" @click="showMenu = !showMenu"></div>
                <i>{{ getMyLanguage("page-active","you-are-in") }}</i>
                <h2>DNE&nbsp;<span>M</span>arket</h2>
            </div>

            <ul class="nav-entities">
                <!-- <div class="entity get-to-know">
                    <i>{{ getMyLanguage("page-active","get-to-know") }}</i>
                    <h2>{{ getMyLanguage("client-space","client-space.home.title") }}</h2>
                </div> -->

                <li>
                    <a :href="`${VUE_APP_INTERNATIONAL_URL}${flagSelected}`" v-if="!$store.state.statusLogin">
                        <h2>DNE&nbsp;<span>T</span>oken</h2>
                    </a>

                    <h2 @click="lnkToken()" v-if="$store.state.statusLogin">DNE&nbsp;<span>T</span>oken</h2>
                </li>

                <li>
                    <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}`" v-if="!$store.state.statusLogin">
                        <h2>DNE&nbsp;<span>E</span>cosystem</h2>
                    </a>
                    
                    <h2 @click="lnkEcosystem()" v-if="$store.state.statusLogin">DNE&nbsp;<span>E</span>cosystem</h2>
                </li>

                <li>
                    <a :href="`${VUE_APP_PAY_URL}${flagSelected}`" v-if="!$store.state.statusLogin">
                        <h2>DNE&nbsp;<span>P</span>ay</h2>
                    </a>
                    
                    <h2 @click="lnkPay()" v-if="$store.state.statusLogin">DNE&nbsp;<span>P</span>ay</h2>
                </li>

                <li>
                    <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/exchange`">
                        <h2>DNE&nbsp;<span>E</span>xchange</h2>
                    </a>
                </li>
            </ul>
        </div>
        
        
        <!-- ACCOUNT -->
        <ul id="nav-dne" v-if="showContent && ( dataSource == 'account' )">
            <li v-for="(menu, index) in menuAccount" :key="index">
                <a :href="`/${flagSelected}/${menu.slug}`" :class="{active: isActive(menu), [menu.name]: true}">
                    <span>{{ getMyLanguage("Account","menu." + menu.name) }}</span>
                </a>
            </li>
        </ul>
        
        
        <!-- MERCHANT -->
        <ul id="nav-dne" class="menu-merchant" v-if="showContent && dataSource == 'merchant'">
            <li v-for="(menu, index) in menuMerchant" :key="index">
                <a :href="`/${flagSelected}/${menu.slug}`" :class="{active: isActive(menu), [menu.name]: true}" v-if="menu.active && menu.active != '0'">
                    <span>{{ getMyLanguage("Merchant","menu." + menu.name) }}</span>
                </a>

                <a :class="['inactive', menu.name]" v-if="!menu.active || menu.active == '0'">
                    <span>{{ getMyLanguage("Merchant","menu." + menu.name) }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>


<script>
    document.addEventListener("click", (event) => {
        const link = event.target.closest("a"); 
        if (!link) return;

        const linkHref = link.href;
        const currentHost = window.location.host; // Actual domain

        // Check external link
        if (linkHref && !linkHref.includes(currentHost)) {
            event.preventDefault();

            // Add hash #redirect before exit
            const newUrl = linkHref.includes("#") ? `${linkHref}&redirect` : `${linkHref}#redirect`;

            // Redirect manually
            setTimeout(() => {
                window.location.href = newUrl;
            }, 100);
        }
    });

    
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        props: {
            dataSource: {
                type: String
            }
        },

        data(){
            return {
                showContent: false,
                flagSelected: "en",

                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                VUE_APP_MARKET_URL: process.env.VUE_APP_MARKET_URL,
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                VUE_APP_PAY_URL: process.env.VUE_APP_PAY_URL,
                VUE_APP_EXCHANGE_URL: process.env.VUE_APP_EXCHANGE_URL,

                storeReference: localStorage.getItem("storeReference"),
                companyReference: localStorage.getItem("companyReference"),

                // menus: [
                //     { slug: "brand/1000049440/apple", name: "Super ofertas" },
                //     { slug: "brand/1000049440/apple", name: "Novidades" },
                //     { slug: "brand/1000049440/apple", name: "3 itens por 10 DNE" },
                //     { slug: "brand/1000049440/apple", name: "Frete grátis" }
                // ],

                menuAccount: [
                    { slug: "account/purchases", name: "purchases" },
                    { slug: "account/purchases/returns", name: "returns" },
                    { slug: "account/purchases#my-account", name: "my-account" },
                    { slug: "account/purchases#vouchers", name: "vouchers" },
                    { slug: "account/purchases#ratings", name: "ratings" }
                ],

                menuMerchant: []
            }
        },

        async mounted(){
            this.showContent = true

            this.menuMerchant = [
                { slug: 'merchant/dashboard', name: 'merchant-dashboard', active: this.storeReference },
                { slug: 'merchant/sales', name: 'merchant-sales', active: this.storeReference },
                { slug: 'merchant/returns', name: 'merchant-returns', active: this.storeReference },
                { slug: 'merchant/products', name: 'merchant-products', active: this.storeReference },
                { slug: 'merchant/campaigns', name: 'merchant-campaigns', active: this.storeReference },
                { slug: 'merchant/store', name: 'merchant-store', active: this.storeReference },
                { slug: `merchant/company/${this.companyReference}`, name: 'merchant-company', active: this.companyReference }
            ]
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            isActive(menu) {
                const slugCheck = `/${this.flagSelected}/${menu.slug}`
                return slugCheck == window.location.pathname;
            },

            lnkEcosystem(){
                this.newtk = localStorage.getItem('accessToken')
                window.location.href = `${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=client-space`
            },

            lnkToken(){
                this.newtk = localStorage.getItem('accessToken')
                window.location.href = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=client-space`
            },
            
            lnkPay(){
                this.newtk = localStorage.getItem('accessToken')
                window.location.href = `${this.VUE_APP_PAY_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=client-space`
            },
            
            lnkExchange(){
                this.newtk = localStorage.getItem('accessToken')
                window.location.href = `${this.VUE_APP_EXCHANGE_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=`
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>



<style lang="scss">
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_dneElements.scss';

    header{
        .col-header{

            &.menus{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            
            // MENU ENTITIES
            .menu-entities{
                @media (min-width: 1200px){
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                @media (max-width: 330px){
                    padding: 2.5rem 1rem;
                }

                @media (min-width: 330px) and (max-width: 1200px){
                    padding: 3rem 2rem;
                }

                @media (max-width: 1200px){
                    @include scrollbars(8px, $color-secondary, #ddd);
                    overflow-y: auto;
                    position: fixed;
                    z-index: 9999;
                    top: 0%;
                    left: -360px;
                    width: calc(100% - 40px);
                    height: 100vh;
                    max-width: 360px;
                    background: #ddd;

                    &.active{
                        transition: 1s;
                        left: 0;

                        .entity{
                            @media (max-width: 1280px){
                                display: block;
                            }
                        }
                    }
                }

                h2{
                    padding: 0 1rem;
                    margin: 0;
                    white-space: nowrap;
                    border-radius: 12px;
                    transition: .3s;
                    cursor: pointer;

                    @media (max-width: 1200px){
                        font-size: 16px;
                        line-height: 40px;
                    }

                    @media (min-width: 1200px){
                        font-size: 16px;
                        line-height: 36px;
                    }

                    span{
                        position: relative;
                        color: $color-secondary;

                        &:after{
                            content: "";
                            display: block;
                            width: 100%;
                            height: 3px;
                            background: #4aae57;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                        }
                    }
                }


                .entity{
                    position: relative;

                    @media (min-width: 1200px){
                        margin-right: 3rem;
                    }

                    @media (max-width: 1200px){
                        display: none;
                    }

                    .openMenu{
                        @media (max-width: 1200px){
                            display: none;
                        }

                        @media (min-width: 1200px){
                            display: block;
                            position: absolute;
                            z-index: 20;
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                        }
                    }

                    i{
                        display: block;
                        font-style: normal;
                        font-size: 10px;
                        white-space: nowrap;
                        position: absolute;
                        z-index: 10;

                        @media (max-width: 1200px){
                            @include shadown;
                            line-height: 20px;
                            color: $color-text-tertiary;
                            padding: 0 10px;
                            border-radius: 12px;
                            background: #aaa;
                            top: -17px;
                            left: .5rem;
                        }

                        @media (min-width: 1200px){
                            top: -15px;
                            left: 1rem;
                            line-height: 12px;
                        }
                    }

                    h2{
                        background: #eee;

                        @media (max-width: 1200px){
                            box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
                        }
                    }


                    // &.hasAnchors{
                    //     @media (min-width: 1200px){
                    //         h2{
                    //             position: relative;
                    //             padding-right: 2.5rem;

                    //             &:after{
                    //                 content: '';
                    //                 display: block;
                    //                 position: absolute;
                    //                 right: 1rem;
                    //                 top: 50%;
                    //                 transform: translateY(-50%);
                    //                 width: 0;
                    //                 height: 0;
                    //                 border-left: 6px solid transparent;
                    //                 border-right: 6px solid transparent;
                    //                 border-top: 6px solid $color-primary;
                    //             }
                    //         }

                    //         &:hover, &.opened{
                    //             h2{
                    //                 background-color: #ddd;
                    //             }
                    //         }
                    //     }
                    // }
                }


                .nav-entities{
                    @media (max-width: 1200px){
                        @include shadown;
                        position: relative;
                        padding: calc(1rem + 40px) 1.5rem 1rem 1.5rem;
                        margin: 4rem auto 1rem;
                        width: calc(100% - 1rem);
                        border-radius: 12px;
                        background: #f5f5f5;
    
                        .get-to-know{
                            position: absolute;
                            top: 0;
                            left: -0.5rem;
                            width: calc(100% + 1rem);
                        }
                        
    
                        li{
                            h2{
                                padding: 0 1rem;
                                margin: 0;
                                white-space: nowrap;
                                border-radius: 12px;
                                transition: .3s;
                                cursor: pointer;
    
                                @media (max-width: 1200px){
                                    font-size: 16px;
                                    line-height: 40px;
                                }
    
                                @media (min-width: 1200px){
                                    font-size: 16px;
                                    line-height: 36px;
                                }
    
                                span{
                                    &:after{
                                        height: 3px;
                                    }
                                }
                            }
                            
                            a{
                                padding: 0 2.5rem 0 38px;
                                white-space: nowrap;
                                border-bottom: 1px solid $color-tertiary;
                                position: relative;
    
                                &:before, &:after{
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
    
                                &:before{
                                    left: 0;
                                    width: 28px;
                                    height: 22px;
                                    border-right: 1px solid #ccc;
                                    background: url('~@/assets/images/icons/dne.svg') no-repeat left center;
                                    background-size: auto 22px;
                                }
    
                                &:after{
                                    width: 6px;
                                    height: 10px;
                                    background: url('~@/assets/images/icons/arrow-btn-menu-mobile.svg') no-repeat center;
                                    background-size: auto 100%;
                                    right: 10px;
                                }
    
                                h2{
                                    padding: 0;
                                }
    
                                &:hover{
                                    &:after{
                                        right: 5px;
                                    }
                                }
                            }
    
                            &:last-child{
                                a{
                                    border-bottom: none;
                                }
                            }
                        }
                    }
    
                    @media (min-width: 1200px){
                        display: flex;
                        flex-wrap: nowrap;
    
                        h2{
                            &:hover{
                                background: #efefef;
                            }
                        }
                        
                        .get-to-know{
                            display: none;
                        }
                    }
    
                    .lnk-ico{
                        @media (max-width: 1200px){
                            background: $color-secondary;
                            border-radius: 12px;
                            margin: .5rem 0;
                            text-align: center;
    
                            &:before, &:after{
                                display: none;
                            }
                        }
                        
                        h2{
                            background: $color-secondary;
                            color: $color-text-tertiary;
                            
                            span{
                                color: $color-text-tertiary;
                                
                                &:after{
                                    background: $color-primary;
                                    transition: .3s;
                                }
                            }
                        }
    
                        @media (min-width: 992px){
                            &:hover{
                                h2{
                                    background: $color-primary;
    
                                    span{
                                        &:after{
                                            background: $color-secondary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


            #nav-dne{
                @media (min-width: 1200px){
                    height: 40px;
                    display: flex;
                    flex-wrap: wrap;
                    overflow-y: hidden;
                }
        
                @media(max-width: 1200px){
                    display: none
                }
        
                li{
                    padding: 0 3px;

                    &.lnk-become-seller{
                        @media (min-width: 1200px){
                            display: none;
                        }
                    }
        
                    &.has-child{
                        &:hover{
                            .submenu{
                                display: flex !important;
                            }
                        }
                    }
        
                    a{
                        font-size: 14px;
                        color: #555;
                        line-height: 40px;
                        display: flex;
                        padding: 0 10px;
                        white-space: nowrap;
                        cursor: pointer;
        
                        -webkit-touch-callout: none;
                        -webkit-user-select: none;
                        -khtml-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
        
                        @media (min-width: 992px){
                            border-radius: 12px;
                            
                            &:hover, &.active{
                                background: #EDEDED;
                                padding: 0 10px;
                            }
                        }

                        &.inactive{
                            filter: blur(2px);
                        }
                    }
        
                    .submenu{
                        display: none;
                        
                        @media (min-width: 1200px){
                            position: absolute;
                            top: 100%;
                            left: 0;
                            padding: 30px 10px;
                            width: 900px;
                            justify-content: center;
                            background: $color-text-tertiary;
                        }
                    
                        .cont{
                            @media (min-width: 1200px){
                                max-width: 1100px;
                                display: flex;
                                justify-content: center;
                            }
                    
                            ul{
                                @media (min-width: 1200px){
                                    width: 700px;
                                }
                            }
                        
                            .banner-submenu{
                                @media (min-width: 1200px){
                                    width: 360px;
                                }
                            }
                        }
                    }
                }
            }
        
        
        
            // MENU MOBILE OPENED
            .menuMobileOpened{
                left: 0;
        
                &:after{
                    opacity: 1;
                }
        
                .closeMenuMobile{
                    opacity: 1;
                    right: 0;
                    transition: .6s;
                    transition-delay: .6s;
                }
        
                #nav-dne{
                    left: 0;
                    transition: .6s;
                }
            }


            // MERCHANT && ACCOUNT
            &.headerMerchant, &.headerAccount{
                #nav-dne{
                    li{
                        a{
                            @media (max-width: 1200px){
                                padding: 0 10px;
                                line-height: 40px;
                            }

                            @media (min-width: 1200px){
                                position: relative;
                                flex-direction: column;
                                align-items: center;
                                line-height: 20px;
                                padding: 3px 6px;

                                &:after{
                                    content: '';
                                    display: block;
                                    width: calc(50% - 16px);
                                    height: 2px;
                                    position: absolute;
                                    bottom: 2px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    background: transparent;
                                    transition: .6s;
                                }
                                
                                &:hover{
                                    background: none;
                                    
                                    &:after{
                                        width: calc(100% - 16px);
                                        background: #ddd;
                                    }
                                }
                            }

                            @media (min-width: 1300px){
                                padding: 3px 8px;
                            }

                            &.active{
                                background: none;

                                &:after{
                                    width: calc(100% - 16px);
                                    background: #aaa;
                                }
                            }
                            
                            &:before{
                                content: '';
                                display: block;

                                @media (max-width: 1200px){
                                    width: 30px;
                                    height: 50px;
                                    background-size: 20px auto !important;
                                    margin-right: 10px;
                                }

                                @media (min-width: 1200px){
                                    width: 16px;
                                    height: 16px;
                                    background-size: 100% auto !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    body.headerFixed{
        header{
            .col-header{
                #nav-dne{
                    li{
                        a{
                            height: 100%;
                            justify-content: center;

                            &:before{
                                opacity: 0;
                                height: 0;
                                animation: hideIconBefore .7s alternate;
                            }
                            
                            &:hover{
                                &:before{
                                    display: none;
                                }
                            }
                        }

                        @keyframes hideIconBefore {
                            0% {opacity: 1;}
                            50% {opacity: 0;}
                            90% {height: 0;}
                            100%{content: initial;}
                        }
                    }
                }
            }
        }
    }


    // SIDEBAR
    .sidebar{
        .sidebar-cont{
            .menu{
                width: 100%;

                .menu-merchant{
                    li{
                        background: #e5e5e5;
                        border-radius: 12px;
                        margin-bottom: 5px;
                        padding: .5rem 1rem !important;

                        @media (min-width: 992px){
                            &:hover{
                                background: $color-text-tertiary;
                            }
                        }

                        a{
                            display: flex;
                            align-items: center;
    
                            &:before{
                                content: '';
                                display: block;
                                width: 16px;
                                height: 16px;
                                margin-right: .5rem;
                                background-size: 100% auto !important;
                                background-color: transparent !important;
                            }
                        }
    
    
                        // MERCHANT
                        &.merchant-dashboard{
                            &:before{
                                background: url('~@/assets/images/icons/menu-manager-dashboard.svg') no-repeat center;
                            }
                        }
    
                        &.merchant-sales{
                            &:before{
                                background: url('~@/assets/images/icons/menu-manager-sales.svg') no-repeat center;
                            }
                        }
                        
                        &.merchant-returns{
                            &:before{
                                background: url('~@/assets/images/icons/menu-manager-returns.svg') no-repeat center;
                            }
                        }
    
                        &.merchant-products{
                            &:before{
                                background: url('~@/assets/images/icons/menu-manager-products.svg') no-repeat center;
                            }
                        }
    
                        &.merchant-campaigns{
                            &:before{
                                background: url('~@/assets/images/icons/menu-manager-campaigns.svg') no-repeat center;
                            }
                        }
    
                        &.merchant-track-orders{
                            &:before{
                                background: url('~@/assets/images/icons/menu-manager-track-orders.svg') no-repeat center;
                            }
                        }
    
                        &.merchant-store{
                            &:before{
                                background: url('~@/assets/images/icons/menu-manager-manage-store.svg') no-repeat center;
                            }
                        }
                        
                        &.merchant-company{
                            &:before{
                                background: url('~@/assets/images/icons/menu-manager-manage-company.svg') no-repeat center;
                            }
                        }
                    }
                }

                .menu-entities{
                    width: 100%;
                    margin-bottom: 1rem;

                    .entity{
                        position: relative;
                        margin-block: 2rem;

                        i{
                            @include shadown;
                            display: block;
                            font-style: normal;
                            font-size: 10px;
                            white-space: nowrap;
                            position: absolute;
                            z-index: 10;
                            line-height: 20px;
                            color: $color-text-tertiary;
                            padding: 0 10px;
                            border-radius: 12px;
                            background: #aaa;
                            top: -17px;
                            left: .5rem;
                        }

                        h2{
                            @include shadown;
                            display: flex;
                            align-items: center;
                            font-size: 18px;
                            color: $color-primary;
                            padding: 0 15px;
                            background: rgba($color: $color-secondary, $alpha: 0.2);
                            border-radius: 12px;
                            border: 2px solid $color-text-tertiary;

                            span{
                                position: relative;
                                color: $color-secondary;

                                &:after{
                                    content: "";
                                    display: block;
                                    width: 100%;
                                    height: 3px;
                                    background: #4aae57;
                                    position: absolute;
                                    left: 0;
                                    top: calc(50% + 8px);
                                }
                            }

                            &:before{
                                content: '';
                                display: block;
                                margin-right: 10px;
                                width: 36px;
                                height: 30px;
                                flex-shrink: 0;
                                background: url(~@/assets/images/icons/dne.svg) no-repeat left;
                                background-size: auto 100% !important;
                                border-right: 1px solid $color-tertiary;
                            }
                        }
                    }

                    li{
                        background: $color-text-tertiary;
                        padding: 0 15px;

                        h2{
                            font-size: 18px;
                            color: $color-primary;
                            height: 50px;
                            display: flex;
                            align-items: center;
                            padding: 0 26px 0 0;
                            margin-bottom: 0;
                            white-space: nowrap;
                            border-bottom: 1px solid #ddd;
                            position: relative;
                            cursor: pointer;

                            &:before{
                                content: '';
                                display: block;
                                margin-right: 10px;
                                width: 36px;
                                height: 30px;
                                flex-shrink: 0;
                                background: url(~@/assets/images/icons/dne.svg) no-repeat left;
                                background-size: auto 100% !important;
                                border-right: 1px solid $color-tertiary;
                            }

                            &:after{
                                content: '';
                                display: block;
                                width: 8px;
                                height: 12px;
                                background: url('~@/assets/images/icons/arrow-btn-menu-mobile.svg') no-repeat center;
                                background-size: auto 100%;
                                position: absolute;
                                right: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                            }

                            span{
                                position: relative;
                                color: $color-secondary;

                                &:after{
                                    content: "";
                                    display: block;
                                    width: 100%;
                                    height: 3px;
                                    background: #4aae57;
                                    position: absolute;
                                    left: 0;
                                    top: calc(50% + 8px);
                                }
                            }
                        }

                        &:last-child{
                            h2{
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }


    // ICONS
    header .col-header, .sidebar .sidebar-cont{
        #nav-dne{
            li{
                a{
                    // MERCHANT
                    &.merchant-dashboard{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-dashboard.svg') no-repeat center;
                        }
                    }

                    &.merchant-sales{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-sales.svg') no-repeat center;
                        }
                    }
                    
                    &.merchant-returns{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-returns.svg') no-repeat center;
                        }
                    }

                    &.merchant-products{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-products.svg') no-repeat center;
                        }
                    }

                    &.merchant-campaigns{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-campaigns.svg') no-repeat center;
                        }
                    }

                    &.merchant-track-orders{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-track-orders.svg') no-repeat center;
                        }
                    }

                    &.merchant-store{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-manage-store.svg') no-repeat center;
                        }
                    }
                    
                    &.merchant-company{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-manage-company.svg') no-repeat center;
                        }
                    }


                    // ACCOUNT
                    &.purchases{
                        &:before{
                            background: url('~@/assets/images/icons/menu-account-purchases.svg') no-repeat center;
                        }
                    }

                    &.returns{
                        &:before{
                            background: url('~@/assets/images/icons/menu-account-returns.svg') no-repeat center;
                        }
                    }

                    &.my-account{
                        &:before{
                            background: url('~@/assets/images/icons/menu-account-my-account.svg') no-repeat center;
                        }
                    }

                    &.vouchers{
                        &:before{
                            background: url('~@/assets/images/icons/menu-account-vouchers.svg') no-repeat center;
                        }
                    }

                    &.ratings{
                        &:before{
                            background: url('~@/assets/images/icons/menu-account-ratings.svg') no-repeat center;
                        }
                    }
                }
            }
        }
    }
</style>