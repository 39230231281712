<template>
    <div class="merchant page-plugin">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- HEADER STORE -->
        <div class="load" v-if="loadMerchantStore"></div>
        <HeaderStore :dataSource="dataMerchantStore" v-if="!loadMerchantStore" />


        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />

        
        <!-- CONTENT -->
        <section class="content-plugin">
            <div class="container">
                <div class="sections">
                    <div class="cont">
                        <div class="btn-section" :class="{active: sectionManage.guide === 'plugin'}" @click='navSection("plugin")'>
                            <!-- <small>{{getMyLanguage("Store","merchant.store.layout.guide-title")}}</small> -->
                            <small>Gerar códigos</small>
                        </div>

                        <div class="btn-section" :class="{active: sectionManage.guide === 'webhook'}" @click='navSection("webhook")'>
                            <!-- <small>{{getMyLanguage("Store","merchant.store.staff.guide-title")}}</small> -->
                            <small>Webhook</small>
                        </div>
                        
                        <div class="btn-section" :class="{active: sectionManage.guide === 'logs'}" @click='navSection("logs")'>
                            <!-- <small>{{getMyLanguage("Store","merchant.store.staff.guide-title")}}</small> -->
                            <small>Logs</small>
                        </div>
                    </div>
                </div>


                <!-- PLUGIN -->
                <div class="cont-plugin" v-if="sectionManage.guide === 'plugin'">
                    <h2 class="title-secondary subtitle">
                        <!-- <small>{{storeName}}</small> -->
                        <!-- {{getMyLanguage("Merchant","campaigns.title")}} -->
                        Integração
                        <small>Plugin DNE Pay</small>
                    </h2>

                    <p class="obs">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Atque ab vel iste perferendis accusantium adipisci id, blanditiis tempore omnis minima, quo nesciunt.</p>

                    <div class="bts-add">
                        <div class="add-plugin" @click="openModal()">
                            <!-- <h3>{{ getMyLanguage("Campaigns","add") }}</h3> -->
                            <h3>Gerar código de venda</h3>
                        </div>

                        <!-- <div class="add-plugin info">
                            <h3>Gerar código informativo</h3>
                        </div> -->
                    </div>
                </div>



                <!-- WEBHOOK -->
                <div class="cont-webhook" v-if="sectionManage.guide === 'webhook'">
                    <h2 class="title-secondary subtitle">
                        Webhook
                        <small>Plugin DNE Pay</small>
                    </h2>

                    <div class="load" v-if="sectionManage.load"></div>

                    <!-- WEBHOOK > LIST -->
                    <div class="webhook-list" v-if="webhookPlace == 'list'">
                        <div class="header-tools header-webhook" v-if="!sectionManage.load">
                            <div class="search-bar">
                                <input type="text" class="form form-search" id="searchWebHook" placeholder="WebHook name" v-on:keyup="filterWebHook">
                            </div>

                            <div class="add-webhook" @click="openWebHook('new')">Create Webhook</div>
                        </div>

                        <div class="scroll-horizontal" v-if="!sectionManage.load">
                            <table class="list-result">
                                <thead class="header-list">
                                    <tr>
                                        <th class="text-left">Name</th>
                                        <th class="full text-left">Target</th>
                                        <th>Status</th>
                                        <th>Type</th>
                                        <th>Created</th>
                                    </tr>
                                </thead>

                                <tbody id="drop-list-webhook">
                                    <tr class="list"
                                        v-for="(item, indexWebHook) in dataWebHook.webhook"
                                        :key="indexWebHook"
                                        @click="openWebHook('upd', item)">

                                        <td class="text-left"><span>{{ item.name }}</span></td>
                                        <td class="full text-left"><span :title="item.address">{{ item.address }}</span></td>
                                        <td class="status" :class="item.status_extra"><span>{{ item.status }}</span></td>
                                        <td><span>{{ item.type.type }}</span></td>
                                        <td><span>{{ dateTime(item.created_at) }}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- <pre>{{dataWebHook.webhook[0]}}</pre> -->
                    </div>


                    <!-- WEBHOOK > NEW / UPD -->
                    <div class="webhook-manager" v-if="webhookPlace == 'manager'">
                        <div class="webhook-form">
                            <div>
                                <label>
                                    <strong>Webhook Name</strong>

                                    <input type="text" class="form" maxlength="50"
                                        v-model="webhookManager.data.name"
                                        :class='{error: errorForm.webhook.name}'
                                        @keydown="errorForm.webhook.name = false">
                                </label>

                                <label>
                                    <strong>Target</strong>

                                    <input type="text" class="form"
                                        v-model="webhookManager.data.address"
                                        :class='{error: errorForm.webhook.address}'
                                        @keydown="errorForm.webhook.address = false"
                                        @blur="validateAddress">
                                    
                                    <div class="error" v-if="errorForm.webhook.address">Invalid URL (target)</div>
                                </label>

                                <label v-if="webhookManager.action == 'upd'" class="secret_code" for="">
                                    <div class="form-group-check">
                                        <div>
                                            <strong>Secret (SHA-256) </strong>
                                            <small>Automatic</small>
                                        </div>

                                        <input
                                            type="checkbox"
                                            id="secretRotate"
                                            class="form-check"
                                            v-model="webhookManager.data.secret_rotate"
                                            @change="handleSecretRotateChange()" />
                                            
                                        <label for="secretRotate" class="form-check-label">
                                            Secret rotate
                                        </label>
                                    </div>

                                    <div class="secret-sha">
                                        <div class="btn-copy"
                                            :class="{copying: sectionManage.copying}"
                                            @click="copyCode(webhookManager.data.secret)"
                                            v-if="webhookManager.data.secret">
                                            
                                            <span>Copy</span>
                                        </div>

                                        <div class="form form-disabled">
                                            <span>{{ webhookManager.data.secret }}</span>
                                        </div>
                                    </div>
                                </label>

                                <label>
                                    <strong>Webhook type</strong>
                                    
                                    <select class="form"
                                        v-if="webhookManager.action == 'new'"
                                        v-model="webhookManager.data.type"
                                        @click="(webhookManager.data.actions = []) && (webhookManager.code = '')">
                                        
                                        <option
                                            :value="item.type_code"
                                            v-for="(item, indexWebHookTypes) in dataWebHook.types"
                                            :key="indexWebHookTypes">
                                            
                                            {{ item.type }}
                                        </option>
                                    </select>

                                    <div class="form form-disabled" v-else>
                                        <span>{{ dataWebHook.types.filter(typeName => typeName.type_code == webhookManager.data.type)[0].type }}</span>
                                    </div>
                                </label>

                                <div class="actions" v-if="webhookManager.data.type">
                                    <label>
                                        <strong>Webhook Actions</strong>
                                    </label>

                                    <div class="empty" v-if="dataWebHook.types.filter(type => type.type_code == webhookManager.data.type)[0].actions.length == 0">No action available</div>

                                    <div class="options" v-else>
                                        <div class="form-group-check" 
                                            v-for="(item, indexWebHookTypes) in dataWebHook.types.filter(type => type.type_code == webhookManager.data.type)[0].actions" 
                                            :key="indexWebHookTypes">
                                            
                                            <input class="form-check"
                                                type="checkbox"
                                                :id="'checkbox-' + item.Id"
                                                :checked="webhookManager.data.actions.includes(item.Id)"
                                                @change="handleCheckboxChange(item, $event)" />
                                                
                                            <label :for="'checkbox-' + item.Id" class="form-check-label">
                                                {{ item.action }}
                                            </label>
                                        </div>
                                    </div>

                                </div>

                                <!-- <pre>{{webhookManager.data}}</pre> -->

                                <a href="#" class="btn-documentation">Webhook Documentation</a>

                                <div class="load" v-if="loadSave"></div>

                                <div class="bts-webhook" v-else>
                                    <div class="bts" v-if="!flowDelete">
                                        <div class="btn-cancel" @click="closeWebHook()">Cancel</div>

                                        <div class="btn-primary"
                                            v-if="webhookManager.action == 'new'"
                                            @click="saveWebhook()">
                                            
                                            Create
                                        </div>

                                        <div class="btn-primary"
                                            v-else
                                            @click="saveWebhook()">
                                            
                                            Update
                                        </div>

                                    </div>

                                    <div class="delete" v-if="webhookManager.action == 'upd' && !flowDelete">
                                        <div class="btn-delete" @click="flowDelete = true">Delete Webhook</div>
                                    </div>

                                    
                                    <!-- DELETE CONFIRM -->
                                    <div class="delete-webhook" v-if="flowDelete">
                                        <p>
                                            <strong>Are you sure you want to delete this Webhook?</strong>
                                        </p>

                                        <div class="bts">
                                            <div class="btn-cancel" @click="flowDelete = false">
                                                No delete
                                            </div>

                                            <div class="btn-primary" @click="deleteWebhook()">
                                                Confirm
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="webhook-code">
                            <div class="btn-copy" :class="{copying: sectionManage.copying}" @click="copyCode(webhookManager.code)" v-if="webhookManager.code"><span>Copy code</span></div>
                            <div class="btn-copy disabled" v-else><span>Copy code</span></div>

                            <div class="code">
                                <pre>{{ webhookManager.code }}</pre>
                            </div>
                        </div>
                    </div>
                </div>



                <!-- LOGS -->
                <div class="cont-logs" v-if="sectionManage.guide === 'logs'">
                    <h2 class="title-secondary subtitle">
                        Logs
                        <small>Plugin DNE Pay</small>
                    </h2>

                    <div class="load" v-if="sectionManage.load"></div>

                    <div class="header-tools header-logs" v-if="!sectionManage.load">
                        <div class="search-bar">
                            <input type="text" class="form form-search" id="searchLogs" placeholder="Reference log" v-on:keyup="filterLogs">
                        </div>
                    </div>

                    <div class="scroll-horizontal" v-if="!sectionManage.load">
                        <table class="list-result">
                            <thead class="header-list">
                                <tr>
                                    <th class="full text-left">Reference</th>
                                    <th>Webhook</th>
                                    <th>Status</th>
                                    <th>HTTP code</th>
                                    <th>Attempt</th>
                                    <th>First sent</th>
                                    <th>Last sent</th>
                                    <th>Next retry</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody id="drop-list-logs">
                                <tr class="list"
                                    v-for="(item, indexLogs) in dataLogs"
                                    :key="indexLogs"
                                    @click="openLog(item)">

                                    <td class="full text-left">
                                        <span>
                                            {{ item.entity_id }}
                                            <small>{{ item.action }}</small>
                                        </span>
                                    </td>
                                    <td><span>{{ item.webhook }}</span></td>
                                    <td class="status" :class="item.status_extra"><span>{{ item.status }}</span></td>
                                    <td><span>{{ item.http_code }}</span></td>
                                    <td><span>{{ item.attempt }}</span></td>
                                    <td><span>{{ dateTime(item.created_at) }}</span></td>
                                    <td><span>{{ dateTime(item.updated_at) }}</span></td>
                                    <td><span v-if="item.next_retry != item.updated_at">{{ dateTime(item.next_retry) }}</span></td>
                                    <td><img src="~@/assets/images/icons/information.svg" alt="Informations" class="icon"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- <pre>{{dataLogs[0]}}</pre> -->
                </div>
            </div>
        </section>
    </div>



    <!-- LOGS > SIDEBAR -->
    <div class="sidebar-logs" v-if="logSidebar.show">
        <div class="close" @click="closeLog()"></div>

        <div class="cont">
            <div class="header-logs">
                <h3>
                    <small>Webhook type:</small>
                    {{ logSidebar.data.webhook }}
                </h3>

                <p><strong>Id: </strong>{{ logSidebar.data.entity_id }}</p>
            </div>

            <div class="details">
                <h3>
                    <span>Event details</span>

                    <span class="load" v-if="resend.load"></span>

                    <span class="btn-resend"
                        @click="resendLog()"
                        v-else>
                        
                        <span>Resend</span>
                    </span>
                </h3>

                <table>
                    <tr>
                        <td><strong>Webhook name</strong></td>
                        <td><span>{{ logSidebar.data.webhook }}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Action</strong></td>
                        <td><span>{{ logSidebar.data.action }}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Status</strong></td>
                        <td class="status" :class="logSidebar.data.status_extra"><span>{{ logSidebar.data.status }}</span></td>
                    </tr>
                    <tr>
                        <td><strong>HTTP code</strong></td>
                        <td><span>{{ logSidebar.data.http_code }}</span></td>
                    </tr>
                    <tr>
                        <td><strong>First sent</strong></td>
                        <td><span>{{ dateTime(logSidebar.data.created_at) }}</span></td>
                    </tr>
                    <tr>
                        <td><strong>Last sent</strong></td>
                        <td><span>{{ dateTime(logSidebar.data.updated_at) }}</span></td>
                    </tr>
                    <tr v-if="logSidebar.data.next_retry != logSidebar.data.updated_at">
                        <td><strong>Next retry</strong></td>
                        <td><span>{{ dateTime(logSidebar.data.next_retry) }}</span></td>
                    </tr>
                </table>
            </div>

            <div class="payload-code">
                <div class="btn-copy"
                    :class="{copying: sectionManage.copying}"
                    @click="copyCode(logSidebar.data.payload)"
                    v-if="logSidebar.data.payload">
                    
                    <span>Copy code</span>
                </div>

                <div class="btn-copy disabled" v-else>
                    <span>Copy code</span>
                </div>

                <div class="code">
                    <pre>{{ convertCode(logSidebar.data.payload) }}</pre>
                </div>
            </div>
        </div>
    </div>




    <!-- MODAL GENERATE CODE -->
    <div class="modal" v-if="modalPlugin.show">
        <div class="modal-cont">
            <div class="modal-close" @click="closeModal()"></div>

            <div class="modal-scroll">
                <h2 class="title-secondary">
                    DNE Pay
                </h2>

                <div class="fields">

                    <!-- PRODUCT -->
                    <div class="field">
                        <!-- <label>
                            <span>
                                Origem do produto
                            </span>
                            <select name="" id="" class="form">
                                <option value="">
                                    Produto avulso
                                </option>

                                <option value="">
                                    Produto disponível também no DNE Market
                                </option>
                            </select>
                        </label> -->


                        <!-- NAME -->
                        <label>
                            <span class="info">
                                Nome do produto
                                <!-- <i><span>Lorem ipsum dolor sit amet consectetur adipisicing elit.</span></i> -->
                            </span>

                            <input type="text" class="form"
                                v-model="pluginCode.items[0].name"
                                @blur="updateUrlIframe()">
                        </label>
                        

                        <!-- IMAGE -->
                        <label>
                            <span class="info">
                                Url da imagem do produto
                                <i><span>Copie o link da imagem e cole aqui.</span></i>
                            </span>

                            <input type="text" class="form"
                                v-model="pluginCode.items[0].image_url"
                                @blur="updateUrlIframe()">
                        </label>


                        <!-- DESCRIPTION -->
                        <label>
                            <span class="info">
                                Breve descrição
                                <i><span>Faça uma breve descrição com no máximo 200 caracteres.</span></i>
                            </span>

                            <span class="cont">{{pluginCode.items[0].description.length}}/200</span>

                            <textarea class="form" maxlength="200"
                                v-model="pluginCode.items[0].description"
                                @blur="updateUrlIframe()"></textarea>
                        </label>

                        <!-- <label>
                            <span>
                                Entregável
                            </span>
                            <select name="" id="" class="form">
                                <option value=""
                                    v-if="modalPlugin.code.type != 'free-shipping' && modalPlugin.code.type != 'cashback'">

                                    {{ getMyLanguage("Campaigns","modal.campaign.type-percent") }}
                                </option>

                                <option value="">
                                    {{ getMyLanguage("Campaigns","modal.campaign.type-currency") }}
                                </option>
                            </select>
                        </label> -->
                    </div>


                    <!-- PRICE -->
                    <div class="field">
                        <label>
                            <span class="info">
                                * Preço do produto
                                <i><span>Valor correspondente a sua moeda corrente.</span></i>
                            </span>
                        </label>

                        <div class="currency-price">
                            <div>
                                <label>
                                    * Sua moeda corrente
                                    <div>
                                        <select class="form" v-model="pluginCode.instrument" @change="updateUrlIframe()">
                                            <option
                                                v-for="currency in modalPlugin.currency"
                                                :key="currency.trade_asset.id"
                                                :value="currency.ticker" >

                                                {{getMyLanguage("Currency","asset." + currency.trade_asset.short)}}
                                            </option>
                                        </select>
                                    </div>
                                </label>
                            </div>

                            <div class="currency">
                                <i>{{modalPlugin.currency.filter(asset => asset.ticker == pluginCode.instrument)[0].trade_asset.short}}</i>
                                
                                <label>
                                    Preço em {{getMyLanguage("Currency","asset." + modalPlugin.currency.filter(asset => asset.ticker == pluginCode.instrument)[0].trade_asset.short)}}
                                    <div>
                                        <input type="text" class="form"
                                            v-model="modalPlugin.formattedPrice"
                                            @blur="updateUrlIframe()" />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>


                    <!-- CAMPAIGN -->
                    <div class="field">
                        <label>
                            <span class="info">
                                Campanha
                                <i><span>Selecione uma campanha para aplicar neste plugin.</span></i>
                            </span>

                            <select class="form"
                                v-model="pluginCode.items[0].campaign_id"
                                @blur="updateUrlIframe()">

                                <option :value="campaign.id" v-for="(campaign, indexCampaign) in modalPlugin.campaigns" :key="indexCampaign">
                                    {{ campaign.name }}
                                </option>
                            </select>
                        </label>
                    </div>


                    <!-- LAYOUT -->
                    <div class="field layout">
                        <label>
                            <span class="info">
                                Layout
                                <i><span>Ative e desative o cabeçalho ou o rodapé neste plugin.</span></i>
                            </span>
                        </label>

                        <div class="options">
                            <div class="form-group-check"
                                :class="{ active: pluginCode.layout[item.key] }"
                                v-for="(item, indexLayout) in modalPlugin.layout" 
                                :key="indexLayout">

                                <input 
                                    class="form-check"
                                    type="checkbox"
                                    :id="'checkbox-' + item.key"
                                    v-model="pluginCode.layout[item.key]" />
                                    
                                <label :for="'checkbox-' + item.key" class="form-check-label" @click="updateUrlIframe()">
                                    {{ item.key }}
                                </label>
                            </div>

                        </div>
                    </div>


                    <!-- TYPE PLUGIN -->
                    <div class="nav-type-plugin" v-if="modalPlugin.iframe.show || modalPlugin.code.show">
                        <div class="type"
                            :class="{active: modalPlugin.typePlugin.code == 'plugin'}"
                            @click="changeTypePlugin('plugin'), updateUrlIframe()">
                                
                            Plugin de Venda
                        </div>

                        <div class="type"
                            :class="{active: modalPlugin.typePlugin.code == 'plugin-info'}"
                            @click="changeTypePlugin('plugin-info'), updateUrlIframe()">
                            
                            Plugin de Informações
                        </div>
                    </div>


                    <!-- PLUGIN -->
                    <h2 class="title-secondary" v-if="modalPlugin.iframe.show || modalPlugin.code.show">Preview</h2>

                    <div class="plugin-iframe" v-if="modalPlugin.code.show">
                        <div v-if="modalPlugin.iframe.show">
                            <iframe :src="modalPlugin.iframe.url" frameborder="0"></iframe>
                        </div>
                    </div>


                    <!-- PLUGIN CODE -->
                    <h2 class="title-secondary" v-if="modalPlugin.code.show">Código do plugin</h2>
                    <div class="plugin-code" v-if="modalPlugin.code.show">
                        <div class="btn-copy" :class="{copying: modalPlugin.code.copying}" @click="copyCodePlugin()">
                            <span>Copiar código</span>
                        </div>

                        <xmp class="code">
                            <span id="headerCode"></span>
                                {{ pluginCode }}
                            <span id="footerCode"></span>
                        </xmp>
                    </div>


                    <!-- URL PAY -->
                    <div class="lnk-payment" v-if="modalPlugin.code.show && modalPlugin.typePlugin.code == 'plugin'">
                        <h2 class="title-secondary">Link de pagamento</h2>

                        <div class="bts center">
                            <div class="btn-primary"
                                v-if="!modalPlugin.urlPay.load && !modalPlugin.urlPay.url"
                                @click="generateLnkPayment()">
                                
                                Gerar link de pagamento
                            </div>
                        </div>

                        <div class="load" v-if="modalPlugin.urlPay.load"></div>

                        <div class="url-pay" v-if="!modalPlugin.urlPay.load && modalPlugin.urlPay.url">
                            <div class="btn-copy" :class="{copying: sectionManage.copying}" @click="copyCode(modalPlugin.urlPay.url)">
                                <span>Copiar link</span>
                            </div>

                            <div class="url">
                                <span>{{modalPlugin.urlPay.url}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Header from "@/components/Essentials/Header.vue";
    import HeaderStore from "@/components/Merchant/HeaderStore.vue";
    import Breadcrumb from "@/components/Merchant/Breadcrumb.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import moment from 'moment';

    import customScriptsGeneral from "./script-store-general.js";
    import customScriptsPlugin from "./script-store-plugin.js";
    import customScriptsWebHooks from "./script-store-webhooks.js";
    import customScriptsLogs from "./script-store-logs.js";

    export default {
        components: {
            Header,
            HeaderStore,
            Breadcrumb
        },

        data(){
            return Object.assign(
                {},
                customScriptsGeneral.data.call(this),
                customScriptsPlugin.data.call(this),
                customScriptsWebHooks.data.call(this),
                customScriptsLogs.data.call(this)
            )
        },

        async mounted(){
            await customScriptsGeneral.mounted.call(this), customScriptsPlugin.mounted.call(this)
        },

        methods: {
            ...customScriptsGeneral.methods,
            ...customScriptsPlugin.methods,
            ...customScriptsWebHooks.methods,
            ...customScriptsLogs.methods,

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('DD/MM/YY HH:mm');
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="./custom-plugin.scss" lang="scss" scoped />
<style src="./custom-webhook.scss" lang="scss" scoped />
<style src="./custom-logs.scss" lang="scss" scoped />