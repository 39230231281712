<template>
    <div class="merchant page-dashboard">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />
        
        <!-- CONTENT -->
        <section class="content-dashboard">
            <div class="container-fluid">
                
                <!-- HEADER STORE -->
                <div class="load" v-if="load.dataMerchantStore"></div>

                <HeaderStore
                :dataSource="dataMerchantStore"
                v-if="!load.dataMerchantStore" />
                
                <div class="row" v-if="!load.dataMostViewed && errorsGet.dataMostViewed != false">
                    <div class="col-12">
                        <div class="box box-error">
                            <h3 class="title-secondary">
                                {{getMyLanguage("box-error","title-error")}}
                            </h3>

                            <p v-if='errorsGet.dataMostViewed != "401"'>
                                {{getMyLanguage("box-error","msg-error")}}
                            </p>

                            <p v-if='errorsGet.dataMostViewed == "401"'>
                                {{getMyLanguage("box-error","401")}}
                            </p>
                        </div>
                    </div>
                </div>
                
                <!-- <div class="dashboard" v-if="!load.dataMostViewed && !errorsGet.dataMostViewed"> -->
                <div class="dashboard" v-if="!errorsGet.dataMostViewed">
                    <div class="row">

                        <!-- OVERVIEW -->
                        <div class="col-12 col-lg-8">

                            <div class="row">
                                <div class="col-12">
                                    <h2 class="dash-title">
                                       {{ getMyLanguage("Merchant","dashboard.overview.title") }}
                                    </h2>
                                </div>

                                <div class="col-12 col-overviews col-status-store">
                                    <div class="box">
                                        <h3 class="box-title">{{dataMerchantStore.name}}</h3>
                                        
                                        <div class="load" v-if="load.dataMerchantStore"></div>

                                        <p class="alert-error" v-if="errorsGet.dataMerchantStore != false">
                                            {{getMyLanguage("error","alert")}}
                                        </p>

                                        <div class="box-cont" v-if="!errorsGet.dataMerchantStore && !load.dataMerchantStore">
                                            <a :href="`/${flagSelected}/merchant/company/${companyReference}?guide=company`" class="box-status Active">
                                                <div class="info">{{ getMyLanguage("Merchant","dashboard.overview.status.lbl.company-information") }}</div>
                                                <div class="status">{{ getMyLanguage("Merchant","dashboard.overview.status.Active") }}</div>
                                            </a>
                                            
                                            <a :href="`/${flagSelected}/merchant/company/${companyReference}?guide=partners`" class="box-status Active">
                                                <div class="info">{{ getMyLanguage("Merchant","dashboard.overview.status.lbl.partners") }}</div>
                                                <div class="status">{{ getMyLanguage("Merchant","dashboard.overview.status.Active") }}</div>
                                            </a>
                                            
                                            <!-- <a :href="`/${flagSelected}/merchant/company/${companyReference}?guide=documents`" class="box-status Warning"> -->
                                            <a :href="`/${flagSelected}/merchant/company/${companyReference}?guide=documents`" class="box-status Active">
                                                <div class="info">{{ getMyLanguage("Merchant","dashboard.overview.status.lbl.documents") }}</div>
                                                <!-- <div class="status">{{ getMyLanguage("Merchant","dashboard.overview.status.Warning") }}</div> -->
                                                <div class="status">{{ getMyLanguage("Merchant","dashboard.overview.status.Active") }}</div>
                                            </a>
                                            
                                            <a :href="`/${flagSelected}/merchant/store?guide=layout`" class="box-status Pending">
                                                <div class="info">{{ getMyLanguage("Merchant","dashboard.overview.status.lbl.store-layout") }}</div>
                                                <div class="status">{{ getMyLanguage("Merchant","dashboard.overview.status.Pending") }}</div>
                                            </a>
                                            
                                            <a :href="`/${flagSelected}/merchant/store?guide=staff`" class="box-status Active">
                                                <div class="info">{{ getMyLanguage("Merchant","dashboard.overview.status.lbl.users") }}</div>
                                                <div class="status">{{ getMyLanguage("Merchant","dashboard.overview.status.Active") }}</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-6 col-overviews">
                                    <div class="box">
                                        <h3 class="box-title products">
                                            {{ getMyLanguage("Merchant","dashboard.overview.products.title") }}
                                        </h3>

                                        <div class="load" v-if="load.dataProducts"></div>
                                        
                                        <p class="alert-error" v-if="errorsGet.dataProducts != false">
                                            {{getMyLanguage("error","alert")}}
                                        </p>

                                        <div class="box-cont" v-if="!errorsGet.dataProducts && !load.dataProducts">
                                            <div class="overview" @click="modal = true">
                                                <div class="info available">
                                                    {{ getMyLanguage("Merchant","dashboard.overview.products.available") }}
                                                </div>

                                                <span class="value">0</span>
                                            </div>

                                            <div class="overview" @click="modal = true">
                                                <div class="info out-of-stock">
                                                    {{ getMyLanguage("Merchant","dashboard.overview.products.out-of-stock") }}
                                                </div>

                                                <span class="value">0</span>
                                            </div>

                                            <div class="overview" @click="modal = true">
                                                <div class="info pending">
                                                    {{ getMyLanguage("Merchant","dashboard.overview.products.pending") }}
                                                </div>

                                                <span class="value">0</span>
                                            </div>
                                        </div>

                                        <a :href="`/${flagSelected}/merchant/products`" v-if="!load.dataProducts" class="btn-view-more products-manager">
                                            {{ getMyLanguage("Merchant","dashboard.overview.products.manage-products") }}
                                        </a>

                                        <div class="btn-view-more primary add-promo" @click="modal = true" v-if="!load.dataMostViewed">
                                            {{ getMyLanguage("Merchant","dashboard.overview.btn.create-promotion") }}
                                            <small>{{ getMyLanguage("Merchant","dashboard.overview.products.create-promotion.info") }}</small>
                                        </div>
                                    </div>
                                </div>


                                <!-- MOST VIEWED -->
                                <div class="col-12 col-lg-6 col-overviews">
                                    <div class="box">
                                        <h3 class="box-title most-viewed">
                                            Mais visualizados
                                        </h3>

                                        <div class="load" v-if="load.dataMostViewed"></div>

                                        <p class="alert-error" v-if="errorsGet.dataMostViewed != false">
                                            {{getMyLanguage("error","alert")}}
                                        </p>

                                        <div class="box-cont" :class="{scroll: showMore}" v-if="!errorsGet.dataMostViewed && !load.dataMostViewed">
                                            <div class="overview-product" style="display: none" v-for="(products, indexMostViewed) in dataMostViewed.slice(0, 5)" :key="indexMostViewed">
                                                <div class="photo">
                                                    <img :src='products.product_variants[0].attachments[0].attachment' :alt="products.name">
                                                </div>
                                                
                                                <div class="info" @click="modal = true">
                                                    <h4>
                                                        {{products.name}}&nbsp;
                                                        <small>{{products.brand.name}}</small>
                                                    </h4>
                                                    
                                                    <!-- <span class="value">
                                                        <del>323,75</del>
                                                        <strong>299,99</strong>
                                                    </span> -->
                                                </div>
                                            </div>

                                            <div class="btn-view-more view-more" v-if="!showMore && dataMostViewed.length >= 5" @click="showMore = true">
                                                Exibir mais
                                            </div>

                                            <!-- SHOW MORE -->
                                            <div v-if="showMore">
                                                <div class="overview-product" v-for="(products, indexMostViewed) in dataMostViewed.slice(5, 15)" :key="indexMostViewed">
                                                    <div class="photo">
                                                        <img :src='products.product_variants[0].attachments[0].attachment' :alt="products.name">
                                                    </div>
                                                    
                                                    <div class="info" @click="modal = true">
                                                        <h4>
                                                            {{products.name}}&nbsp;
                                                            <small>{{products.brand.name}}</small>
                                                        </h4>
                                                        
                                                        <!-- <span class="value">
                                                            <del>323,75</del>
                                                            <strong>299,99</strong>
                                                        </span> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="btn-view-more primary add-promo" @click="modal = true" v-if="!load.dataMostViewed">
                                            Criar promoção
                                            <small>Produtos mais procurados</small>
                                        </div>
                                    </div>
                                </div>


                                <!-- LATEST SALES -->
                                <div class="col-12 col-lg-6 col-overviews">
                                    <div class="box">
                                        <h3 class="box-title latest-sales">
                                            Últimas vendas
                                        </h3>

                                        <div class="load" v-if="load.dataLatestSales"></div>

                                        <p class="alert-error" v-if="errorsGet.dataLatestSales != false">
                                            {{getMyLanguage("error","alert")}}
                                        </p>

                                        <div class="box-cont" v-if="!errorsGet.dataLatestSales && !load.dataLatestSales">

                                            <a class="overview"
                                            :href="`/${flagSelected}/merchant/sales/${purchase.reference}`"
                                            v-for="(purchase, indexPurchase) in dataLatestSales"
                                            :key="indexPurchase">

                                                <div class="info">
                                                    {{purchase.customer.full_name}}
                                                    <small>{{dateTime(purchase.created_at)}}</small>
                                                </div>

                                                <span class="price">
                                                    {{purchase.summary.total_price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </a>
                                        </div>

                                        <a :href="`/${flagSelected}/merchant/sales`" class="btn-view-more all-sales" v-if="!load.dataLatestSales">
                                            Todas as vendas
                                        </a>

                                        <div class="btn-view-more primary add-promo" @click="modal = true" v-if="!load.dataMostViewed">
                                            Criar promoção
                                            <small>Para clientes voltarem a comprar</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-6 col-overviews">
                                    <div class="box">
                                        <h3 class="box-title delivery">Entregas</h3>

                                        <div class="load" v-if="load.dataShipping"></div>
                                        
                                        <p class="alert-error" v-if="errorsGet.dataShipping != false">
                                            {{getMyLanguage("error","alert")}}
                                        </p>

                                        <div class="box-cont" v-if="!errorsGet.dataShipping && !load.dataShipping">
                                            <div class="overview" @click="modal = true">
                                                <div class="info delivering">
                                                    Pendente de envio
                                                </div>

                                                <span class="value">0</span>
                                            </div>

                                            <div class="overview" @click="modal = true">
                                                <div class="info returned">
                                                    Retornadas
                                                </div>

                                                <span class="value">0</span>
                                            </div>

                                            <div class="overview" @click="modal = true">
                                                <div class="info delivering">
                                                    Em andamento
                                                </div>

                                                <span class="value">0</span>
                                            </div>
                                            
                                            <div class="overview" @click="modal = true">
                                                <div class="info delivered">
                                                    Concluídas
                                                </div>

                                                <span class="value">0</span>
                                            </div>
                                        </div>

                                        <div class="btn-view-more track-orders" @click="modal = true" v-if="!load.dataShipping">
                                            Rastrear pedidos
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- STATISTICS -->
                        <div class="col-12 col-lg-4">

                            <div class="row row-statistics">
                                <div class="col-12">
                                    <!-- <h2 class="dash-title">{{getMyLanguage("Merchant","dashboard.title.statistics")}}</h2> -->
                                    <h2 class="dash-title">
                                        Resumo financeiro
                                    </h2>
                                </div>

                                <div class="col-12 col-statistics">
                                    <div class="box">
                                        <div class="select-date">
                                            <h3 class="filter-date" @click="modal = true">
                                                Fevereiro/24
                                            </h3>
                                        </div>

                                        <div class="load" v-if="load.dataChart"></div>

                                        <p class="alert-error" v-if="errorsGet.dataChart != false">
                                            {{getMyLanguage("error","alert")}}
                                        </p>

                                        <div class="chart" v-if="!errorsGet.dataChart && !load.dataChart">
                                            <Chart :options="dataChart" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-statistics">
                                    <div class="load" v-if="load.dataStatistics"></div>

                                    <p class="alert-error" v-if="errorsGet.dataStatistics != false">
                                        {{getMyLanguage("error","alert")}}
                                    </p>

                                    <div class="box" v-if="!errorsGet.dataStatistics && !load.dataStatistics">
                                        <div class="box-cont division">
                                            <div class="overview abandoned-carts" @click="modal = true">
                                                <div class="info">
                                                    Produtos em carrinhos
                                                    <small>* Adicionados em carrinhos atualmente</small>            
                                                </div>

                                                <span class="value">0 itens / <span class="price">0</span></span>
                                            </div>

                                            <div class="btn-view-more primary add-promo" @click="modal = true">
                                                Criar promoção
                                                <small>Carrinho abandonado</small>
                                            </div>
                                        </div>

                                        <div class="box-cont division">
                                            <div class="overview" @click="modal = true">
                                                <div class="info">
                                                    Vendas fechadas
                                                </div>

                                                <span class="price">0</span>
                                            </div>

                                            <div class="overview" @click="modal = true">
                                                <div class="info">
                                                    Total
                                                </div>

                                                <span class="price">0</span>
                                            </div>

                                            <div class="overview" @click="modal = true">
                                                <div class="info">
                                                    Ticket médio
                                                </div>

                                                <span class="price">0</span>
                                            </div>
                                        </div>

                                        <div class="box-cont">                                    
                                            <div class="overview" @click="modal = true">
                                                <div class="info">
                                                    Valor já levantado
                                                </div>

                                                <span class="price">0</span>
                                            </div>

                                            <div class="overview" @click="modal = true">
                                                <div class="info">
                                                    Saldo disponível
                                                </div>

                                                <span class="price">0</span>
                                            </div>
                                        </div>

                                        <div class="btn-view-more secondary withdraw" @click="modal = true">
                                            Fazer levantamento
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- MODAL -->
        <div class="modal" v-if="modal">
            <div class="modal-cont">
                <div class="modal-close" @click="modal = false"></div>
                <div class="modal-scroll">
                    <h3 class="title-secondary">
                        Título do modal
                    </h3>

                    <p style="text-align: center">*** Em breve ***</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import HeaderStore from "@/components/Merchant/HeaderStore.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMerchant from '@/config/apiMerchant.js'

    import bus from '@/eventBus';

    import moment from 'moment';

    import { Chart } from 'highcharts-vue'

    export default {
        components: {
            Header,
            HeaderStore,
            Chart
        },

        data() {
            return {
                flagSelected: "en",
                headerStore: "",
                headerTemplate: "merchant",
                dataMerchantStore: {},

                load: {
                    dataMerchantStore: true,
                    dataProducts: true,
                    dataMostViewed: true,
                    dataShipping: true,
                    dataLatestSales: true,
                    dataChart: true,
                    dataStatistics: true
                },

                dataMostViewed: [],
                dataLatestSales: [],

                dataChart: {
                    series: [{
                        data: [0,0,0]
                    }]
                },

                modal: false,

                showMore: false,

                // load: true,
                // dataPurchases: {},

                errorsGet: {
                    dataMerchantStore: false,
                    dataProducts: false,
                    dataMostViewed: false,
                    dataShipping: false,
                    dataLatestSales: false,
                    dataChart: false,
                    dataStatistics: false
                },


                // TEMP
                companyReference: ""
            }
        },

        async mounted() {
            this.languageSelected()

            setTimeout(() => {
                this.companyReference = localStorage.getItem("companyReference")
            }, 300);

            // HEADER STORE
            await apiMerchant('/api/v1/market/store')
            .then(response => {
                setTimeout(() => {
                    this.dataMerchantStore = response.data.store
                    this.load.dataMerchantStore = false
                    // console.log(this.dataMerchantStore)
                }, 500);
            })
            .catch(error => {
                setTimeout(() => {
                    this.errorsGet.dataMerchantStore = error.response.status
                    this.load.dataMerchantStore = false
                    localStorage.removeItem("storeReference")
                }, 500);
            })


            // PRODUCTS
            setTimeout(() => {
                this.load.dataProducts = false
            }, 500);

            
            // MOST VIEWED
            await apiMerchant.get('/api/v1/market/product/filter?include_variants=false&include_attachments=true&compressed_response=false')
            .then(response => {
                setTimeout(() => {
                    this.dataMostViewed = response.data
                    this.load.dataMostViewed = false
                    
                    // console.log(this.dataMostViewed)
                }, 500);
                
                // let listProduct = []
                // response.data.forEach(product => {
                //     product.product_variants.forEach(pv => {
                //         pv.brand = product.brand
                //         listProduct.push(pv)
                //     })
                // });
                
                // this.listProducts = listProduct

            })
            .catch(error => {
                this.load.dataMostViewed = false
                this.errorsGet.dataMostViewed = JSON.stringify(error.response.status)

                if ( error.response.status == 401 ){
                    bus.emit('open-login',"401");
                }
            })


            // SHIPPING
            setTimeout(() => {
                this.load.dataShipping = false
            }, 500);


            // LATEST SALES
            await apiMerchant.get('/api/v1/market/order/filter')
            .then(response => {
                setTimeout(() => {
                    this.dataLatestSales = response.data
                    this.load.dataLatestSales = false
                    
                    // console.log(this.dataLatestSales)
                }, 500);
            })
            .catch(error => {
                setTimeout(() => {
                    this.errorsGet.dataLatestSales = error.response.status
                    this.load.dataLatestSales = false
                    
                    console.log(error)
                }, 500);
            })

            
            // CHART
            setTimeout(() => {
                this.load.dataChart = false
            }, 500);


            // STATISTICS
            setTimeout(() => {
                this.load.dataStatistics = false
            }, 500);


            // await api.get('/api/v1/market/order/filter')
            // .then(response => {
            //     this.dataPurchases = response.data
                
            //     setTimeout(() => {
            //         this.load = false
            //     }, 500);
            // })
            // .catch(error => {
            //     this.errorsGet.dataPurchases = error.response.status

            //     setTimeout(() => {
            //         this.load = false
            //     }, 500);
            // })
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="./custom-dashboard.scss" lang="scss" scoped />